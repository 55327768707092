import React from "react";
import "./Home.css";
import { Link } from "react-router-dom";
const Home = () => {
  return (
    <header className="home" id="home">
      <div className="container">
        <div className="home-subheading">
          Elevate your business with strategic IT consulting, cutting-edge
          solutions, and reliable support for success through innovation.
        </div>
        <div className="main-heading">Innovation, Strategic, Empowerment</div>

        <Link to={"/production"}>
          <button className="button-80">Know More</button>
        </Link>
      </div>
    </header>
  );
};

export default Home;
