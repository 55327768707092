// Header.js

import React from 'react';
import Home from '../Home/Home';
import About from '../About/About';
import Footer from '../Footer/Footer';
import Services from '../Services/Services';
import Navbar from '../Navbar';
import Technology from '../Technology/Technology';

const Header = () => {


  return (
    <div>
  
  <link rel="icon" type="image/x-icon" href="assets/favicon.ico" />
 
  <link href="css/styles.css" rel="stylesheet" />
  <Navbar/>

   <Home/>
 
  
  <About/>

 

  <Technology/>
 
  <Services/>
  
 
      <Footer/>     
     
        

    

 
</div>

  );
};

export default Header;
