import React, { useState } from "react";
import "./Navbar.css";

const Navba = () => {
  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  return (
    <header id="header" className="fixed-top">
      <div className="container d-flex align-items-center justify-content-between">
        <img className="logo" src="assets/img/about/bluelogo.png" alt="" />

        <nav className="navbar" id="navbar">
          <div className={`menu-icon`} onClick={toggleMenu}>
            <i className={`fas ${showMenu ? "fa-times" : "fa-bars"} ms-1 ${showMenu ? "open" : ""}`}></i>
          </div>

          <ul className={`navbar-list ${showMenu ? "show" : ""}`}>
            <li>
              <a className="nav-link scrollto active" href="#home">
                Home
              </a>
            </li>
            <li>
              <a className="nav-link scrollto" href="#about">
                About
              </a>
            </li>
            <li>
              <a className="nav-link scrollto" href="#technology">
                Technologies
              </a>
            </li>
            <li>
              <a className="nav-link scrollto" href="#services">
                Services
              </a>
            </li>
            
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Navba;