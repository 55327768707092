import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
  <footer id="footer">
 
  <div className="footer-top">
    <div className="container">
      <div className="row">
        <div className="col-lg-3 col-md-6 footer-contact">
          <h4>Blue Consultech <br /> and IT Services</h4>
          <p>
          Plot No 16, Vivekanand <br/> Society Gadchandur<br />
          Korpana Chandrapur<br />
          <br /><br />
          
          </p>
        </div>
        <div className="col-lg-3 col-md-6 footer-links">
          <h4>Links</h4>
          <ul>
            <li><i className="bx bx-chevron-right" /> <a href="/home">Home</a></li>
            <li><i className="bx bx-chevron-right" /> <a href="/about">About Us</a></li>
            <li><i className="bx bx-chevron-right" /> <a href="/technologies">Technologies</a></li>
            <li><i className="bx bx-chevron-right" /> <a href="/Services">Services</a></li>
          </ul>
        </div>
        <div className="col-lg-3 col-md-6 footer-links">
          <h4>Our Services</h4>
          <ul>
            <li><i className="bx bx-chevron-right" /> <a href="/#">Web Development</a></li>
            <li><i className="bx bx-chevron-right" /> <a href="/#">App Development</a></li>
            <li><i className="bx bx-chevron-right" /> <a href="/#">HR Operations</a></li>
            <li><i className="bx bx-chevron-right" /> <a href="/#">Business Incorporation</a></li>
          </ul>
        </div>
        <div className="col-lg-3 col-md-6 footer-links">
          <h4>Our Social Networks</h4>
          <p>Join us in building connections and empowering lives.</p>
          <div className="social-links mt-3">
            <a href="/#" className="twitter"><i className="bx bxl-twitter" /></a>
            <a href="/#" className="facebook"><i className="bx bxl-facebook" /></a>
            <a href="/#" className="linkedin"><i className="bx bxl-linkedin" /></a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="container footer-bottom clearfix">
    <div className="copyright">
      © <strong><span>Blue</span></strong>Consultech and IT Services
    </div>
    <div className="credits">
      Privacy Policy | Cookie Policy
    </div>
  </div>
</footer>

  )
}

export default Footer
