import React from "react";
import './Service.css'

const Services = () => {
  return (
    <section className="page-section" id="services">
      <div className="container">
        <div className="text-center">
          <h2 className="section-heading text-uppercase">Services</h2>
          <h3 className="section-subheading text-muted">

          </h3>
        </div>
        <div className="row text-center">
          <div className="col-md-4">
            <span className="fa-stack fa-4x">
              <i className="fas fa-circle fa-stack-2x text-primary" />
              <i className="fas fa-users-cog fa-stack-1x fa-inverse" />
              
            </span>
            <h4 className="my-3">Human Resource Management</h4>
          
            <p className="text-muted">
              Blue Consultech specializes in Human Resource Management, offering
              recruitment, training, and compliance solutions. Elevate your
              workforce with our tailored services, ensuring HR excellence and
              employee satisfaction.
            </p>
          
           
          </div>

          <div className="col-md-4">
            <span className="fa-stack fa-4x">
              <i className="fas fa-circle fa-stack-2x text-primary" />
              <i className="fas fa-solid fa-business-time fa-stack-1x fa-inverse"></i>
            </span>
            <h4 className="my-3">Business Incorporation </h4>
            <p className="text-muted">
              Explore seamless Business Incorporation with Blue Consultech. We
              guide businesses through structure selection, handle
              registrations, and provide ongoing compliance support. Trust us
              for a smooth, legally sound foundation for your success.
            </p>
          
          </div>
          <div className="col-md-4">
            <span className="fa-stack fa-4x">
              <i className="fas fa-circle fa-stack-2x text-primary" />
              <i className="fas fa-laptop fa-stack-1x fa-inverse" />
            </span>
            <h4 className="my-3">Web and App Development</h4>
           
            <p className="text-muted">
              Blue Consultech excels in creating advanced Web and App
              Development solutions for businesses of all sizes. Our dedicated
              team ensures innovative designs and robust development, empowering
              businesses to thrive online. 
            </p>
          
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
