import React from 'react'
import './Production.css'

const Production = () => {
  return (
    <div className="production" id='production'>
    <h1 className="heading">Site Under Production</h1>
    <p className="message">
      We apologize for the inconvenience, but our site is currently undergoing scheduled maintenance.
      Please check back later. Thank you for your patience.
    </p>
  </div>
  )
}

export default Production
