import React from 'react';
import './About.css'

const About = () => {
  return (
   <section id="about" className="about">
  <div className="container" data-aos="fade-up">
    <div className="section-title">
      <h2>About</h2>
    </div>
    <div className="row content">
      <div className="col-lg-6">
        <p>
        Welcome to Blue Consultech, where expertise meets innovation.
        </p>
        <ul>
          <li><i className="ri-check-double-line" /> Blue Consultech offers diverse expertise and cutting-edge innovation for dynamic solutions.</li>
          <li><i className="ri-check-double-line" /> We prioritize strategic partnerships, developing holistic, customized solutions for long-term success.</li>
          <li><i className="ri-check-double-line" /> we offer tailored website design and app development.</li>
        </ul>
      </div>
      <div className="col-lg-6 pt-4 pt-lg-0">
        <p>
       As a premier consulting firm, we specialize in providing comprehensive services tailored for both employed professionals and aspiring entrepreneurs. Whether you're seeking strategic career guidance or embarking on a new business venture, Blue Consultech is your trusted partner in navigating the path to success. Our seasoned team of consultants is committed to delivering personalized solutions, empowering individuals to achieve their goals and businesses to thrive in an ever-evolving market. At Blue Consultech, we believe in turning aspirations into achievements, one consultation at a time
        </p>
        <a href="/production" className="btn-learn-more">Learn More</a>
      </div>
    </div>
  </div>

  
</section>

  )
}

export default About
